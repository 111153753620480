@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

:root {
  --white: #fff;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
}

::-webkit-scrollbar-thumb:hover {
  background: #1f2762;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.animate {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.rec.rec-arrow {
  width: 35px;
  height: 35px;
  line-height: 35px;
  align-self: center;
  min-width: 0;
  font-size: 1em;
  border-radius: 3px;
  background-color: #eee;
  color: #ff1493;
}

.rec.rec-arrow:hover:enabled,
.rec.rec-arrow:hover:enabled {
  background-color: #1f2762;
}

.rec.rec-arrow:disabled {
  visibility: hidden;
}
